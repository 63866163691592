// React and Hooks
import { useMemo, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import useGenerateWorkOrderRedirectPath from 'components/LaborTicket/hooks/useGenerateWorkOrderRedirectPath';
import useOperatorLogout from 'components/LaborTicket/hooks/useOperatorLogout';
import useHandlePauseOperation from 'components/LaborTicket/useHandlePauseOperation';
// Actions
// Selectors
import { getLaborTicketAction } from 'lib/selectors/getLaborTicketAction';
import { getHasOpenSetupActivity } from 'lib/selectors/getHasOpenSetupActivity';

// Utils
import PropTypes from 'prop-types';
import { FLAG_KEYS, LABOR_TICKET_ACTIONS } from 'lib/constants';

/**
 * Custom hook to handle setup pause actions with stop job and sign out flag.
 *
 * @param {Object} params - The parameters for the hook .
 * @param {boolean} params.laborTicketMarkedAsComplete - Indicates if the labor ticket is marked as complete.
 * @param {Function} params.requestLaborTicketAction - Function to request a labor ticket action.
 * @param {Function} params.handleSubmit - Function to handle form submission.
 * @returns {Object} The state and handlers for the setup pause actions.
 */
const useSetupPauseActionsWithStopJobAndSignOutFlag = ({
  laborTicketMarkedAsComplete,
  requestLaborTicketAction,
  handleSubmit,
}) => {
  const flags = useFlags();
  const requestOperatorLogout = useOperatorLogout();
  const [isLogout, setIsLogout] = useState(false);
  const [showIncompleteSetupModal, setShowIncompleteSetupModal] = useState(
    false
  );
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const workOrderRedirectPath = useGenerateWorkOrderRedirectPath({
    excludeRefFromPath: true,
  });

  const laborTicketAction = useSelector(getLaborTicketAction);
  const hasOpenSetupActivity = useSelector(getHasOpenSetupActivity);

  const isStopAndCreate =
    laborTicketAction === LABOR_TICKET_ACTIONS.STOP_AND_CREATE;
  const enableStopJobAndSignOut =
    flags[FLAG_KEYS.STOP_INCOMPLETE_JOB_AND_SIGN_OUT_ENABLED];
  const shouldShowIncompleteSetupModal = useMemo(() => {
    return (
      enableStopJobAndSignOut &&
      hasOpenSetupActivity &&
      isStopAndCreate &&
      !laborTicketMarkedAsComplete
    );
  }, [
    enableStopJobAndSignOut,
    hasOpenSetupActivity,
    isStopAndCreate,
    laborTicketMarkedAsComplete,
  ]);

  // close the confirmation modal and open the incomplete setup modal
  const handleCancelConfirmationModal = () => {
    setShowConfirmationModal(false);
    setShowIncompleteSetupModal(true);
  };

  // close the incomplete setup modal and open the confirmation modal
  const handleConfirmStopJob = () => {
    setShowIncompleteSetupModal(false);
    setShowConfirmationModal(true);
  };

  // close both modals
  const handleClose = () => {
    setShowConfirmationModal(false);
    setShowIncompleteSetupModal(false);
  };

  const handleStopJob = useCallback(() => {
    if (isLogout) {
      requestLaborTicketAction({
        action: LABOR_TICKET_ACTIONS.STOP_RUN,
        opts: { operatorLogout: true },
      });
      return requestOperatorLogout();
    }
    return handleSubmit({
      workOrderRedirectPath,
    });
  }, [
    handleSubmit,
    isLogout,
    workOrderRedirectPath,
    requestLaborTicketAction,
    requestOperatorLogout,
  ]);

  const onPaused = () => {
    setShowIncompleteSetupModal(false);
    if (isLogout) {
      requestOperatorLogout();
    }
  };
  const handlePause = useHandlePauseOperation({ onPaused });

  return {
    isLogout,
    setIsLogout,
    setShowIncompleteSetupModal,
    showIncompleteSetupModal,
    showConfirmationModal,
    shouldShowIncompleteSetupModal,
    handleCancelConfirmationModal,
    handleConfirmStopJob,
    handleStopJob,
    handlePause,
    handleClose,
  };
};

useSetupPauseActionsWithStopJobAndSignOutFlag.propTypes = {
  laborTicketMarkedAsComplete: PropTypes.bool.isRequired,
  requestLaborTicketAction: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default useSetupPauseActionsWithStopJobAndSignOutFlag;
