// React and Hooks
import { useMemo, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import useOperatorLogout from 'components/LaborTicket/hooks/useOperatorLogout';
import useHandlePauseOperation from 'components/LaborTicket/useHandlePauseOperation';
import useGenerateWorkOrderRedirectPath from 'components/LaborTicket/hooks/useGenerateWorkOrderRedirectPath';
// Actions
// Selectors
import { getLaborTicketAction } from 'lib/selectors/getLaborTicketAction';
import { getHasOpenSetupActivity } from 'lib/selectors/getHasOpenSetupActivity';
// Utils
import PropTypes from 'prop-types';
import { LABOR_TICKET_ACTIONS } from 'lib/constants';
// Components

/**
 * Custom hook to manage setup pause actions.
 * @param {Object} params - The parameters.
 * @param {boolean} params.laborTicketMarkedAsComplete - Indicates if the labor ticket is marked as complete.
 * @param {Function} params.requestLaborTicketAction - Function to request a labor ticket action.
 * @param {Function} params.handleSubmit - Function to handle form submission.
 */
const useSetupPauseActions = ({
  laborTicketMarkedAsComplete,
  requestLaborTicketAction,
  handleSubmit,
}) => {
  // Hook to handle operator logout
  const requestOperatorLogout = useOperatorLogout();
  // State to manage the visibility of the setup pause actions modal
  const [
    isSetupPauseActionsModalOpen,
    setIsSetupPauseActionsModalOpen,
  ] = useState(false);
  // State to manage the visibility of the setup confirm stop job modal
  const [
    isSetupConfirmStopJobModalOpen,
    setIsSetupConfirmStopJobModalOpen,
  ] = useState(false);
  // Selectors to get necessary data from the Redux store
  const laborTicketAction = useSelector(getLaborTicketAction);
  const hasOpenSetupActivity = useSelector(getHasOpenSetupActivity);
  const workOrderRedirectPath = useGenerateWorkOrderRedirectPath({
    excludeRefFromPath: true,
  });

  // Memoized value to determine if the setup pause action modal can be opened
  const canOpenSetupPauseActionModal = useMemo(() => {
    return (
      [
        LABOR_TICKET_ACTIONS.STOP_AND_CREATE,
        LABOR_TICKET_ACTIONS.STOP_AND_CREATE_OPERATOR_LOGOUT,
      ].includes(laborTicketAction) &&
      hasOpenSetupActivity === true &&
      !laborTicketMarkedAsComplete
    );
  }, [laborTicketAction, hasOpenSetupActivity, laborTicketMarkedAsComplete]);

  const isLogoutAction =
    laborTicketAction === LABOR_TICKET_ACTIONS.STOP_AND_CREATE_OPERATOR_LOGOUT;

  // Callback to handle stopping the job
  const handleStopJob = useCallback(() => {
    if (isLogoutAction) {
      requestLaborTicketAction({
        action: LABOR_TICKET_ACTIONS.STOP_RUN,
        opts: { operatorLogout: true },
      });
      return requestOperatorLogout();
    }
    return handleSubmit({
      workOrderRedirectPath,
    });
  }, [
    handleSubmit,
    isLogoutAction,
    workOrderRedirectPath,
    requestLaborTicketAction,
    requestOperatorLogout,
  ]);

  // Callback to handle the pause action
  const onPaused = () => {
    setIsSetupPauseActionsModalOpen(false);
    if (isLogoutAction) {
      requestOperatorLogout();
    }
  };
  const handlePause = useHandlePauseOperation({ onPaused });

  // Callback to handle closing the modals
  const handleClose = () => {
    setIsSetupPauseActionsModalOpen(false);
    setIsSetupConfirmStopJobModalOpen(false);
  };

  // Callback to handle confirming the stop action
  const handleConfirmStop = () => {
    setIsSetupPauseActionsModalOpen(false);
    setIsSetupConfirmStopJobModalOpen(true);
  };

  // Callback to handle canceling the stop action
  const handleCancel = () => {
    setIsSetupConfirmStopJobModalOpen(false);
    setIsSetupPauseActionsModalOpen(true);
  };

  return {
    isSetupPauseActionsModalOpen,
    isSetupConfirmStopJobModalOpen,
    setIsSetupPauseModalOpen: setIsSetupPauseActionsModalOpen,
    canOpenSetupPauseActionModal,
    handleStopJob,
    handlePause,
    handleClose,
    handleConfirmStop,
    handleCancel,
    isSignOut: isLogoutAction,
  };
};

useSetupPauseActions.propTypes = {
  laborTicketMarkedAsComplete: PropTypes.bool,
  requestLaborTicketAction: PropTypes.func,
  handleSubmit: PropTypes.func,
};

export default useSetupPauseActions;
