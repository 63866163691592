import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import _get from 'lodash/get';
import moment from 'moment-timezone';
import { now, toISO } from 'lib/utils/date';
import { useDispatch, useSelector } from 'react-redux';
import { Icon, palette, LoadingCircle } from '@m12s/component-library';
import { farTicketAlt, spinner } from 'lib/icons';

import { getLatestJobName } from 'lib/selectors/getLatestJobName';
import { getCurrentLaborTicket } from 'lib/selectors/getLaborTickets';
import { getScopeStart } from 'lib/selectors/getScopeStart';
import { getLatestActivityType } from 'lib/selectors/getLatestActivityType';
import { getCurrentWorkOrderOp } from 'lib/selectors/getCurrentWorkOrderOp';
import { useMutation } from '@apollo/client';
import { CREATE_PART_ADJUSTMENT } from 'lib/api/mutations';
import { actionCreatePartAdjustmentSuccess } from 'lib/actions';
import { getMachine } from 'lib/selectors/getMachine';
import { getLaborScopeTotalParts } from 'lib/selectors/getScopeTotalParts';
import { getPartAdjustmentTypes } from 'lib/selectors/getPartAdjustmentTypes';

import { FLAG_KEYS, LABOR_TICKET_ACTIONS } from 'lib/constants';
import { getHasOpenSetupActivity } from 'lib/selectors/getHasOpenSetupActivity';
import { getLaborTicketAction } from 'lib/selectors/getLaborTicketAction';
import { getProductionActivityTypeRefs } from 'lib/selectors/getProductionActivityTypeRefs';
import { getSetupActivityTypeRefs } from 'lib/selectors/getSetupActivityTypeRefs';
import { getOperators } from 'lib/selectors/getOperators';
import { useFlags } from 'launchdarkly-react-client-sdk';
import useGenerateWorkOrderRedirectPath from './hooks/useGenerateWorkOrderRedirectPath';
import useSubmitLaborTicket from './useSubmitLaborTicket';

import Card from './Card';
import { LaborTicketItem, ModalButton } from './styled';
import SetupStopJobOrPauseModal from './SetupStopJobOrPauseModal';
import SetupConfirmStopJobModal from './SetupConfirmStopJobModal';
import SetupIncompleteModal from './SetupIncompleteModal';
import useSetupPauseActions from './useSetupPauseActions';
import useSetupPauseActionsWithStopJobAndSignOutFlag from './useSetupPauseActionsWithStopJobAndSignOutFlag';
import useSetupLaborTicketStopOrLeaveModal from './useSetupLaborTicketStopOrLeaveModal';
import useProductionLaborTicketStopOrLeaveModal from './useProductionLaborTicketStopOrLeaveModal';
import useLaborTicketMarkAsCompleteModal from './useLaborTicketMarkAsCompleteModal';
import useOperatorLogout from './hooks/useOperatorLogout';

const BodyContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const Row = styled.div`
  display: flex;
  gap: 0.5rem;

  > * {
    min-width: 38px;
  }
`;

const mapLaborTicketAction = (action) => {
  switch (action) {
    case LABOR_TICKET_ACTIONS.STOP_AND_CREATE:
      return LABOR_TICKET_ACTIONS.STOP_RUN;
    case LABOR_TICKET_ACTIONS.STOP_AND_CREATE_OPERATOR_LOGOUT:
      return LABOR_TICKET_ACTIONS.OPERATOR_LOGOUT;
    default:
      return action;
  }
};

const LaborTicketCard = ({ form, loading, handleFormChange }) => {
  const dispatch = useDispatch();

  const flags = useFlags();
  const enableStopJobAndSignOut =
    flags[FLAG_KEYS.STOP_INCOMPLETE_JOB_AND_SIGN_OUT_ENABLED];

  const latestActivityType = useSelector(getLatestActivityType);
  const partAdjustmentTypes = useSelector(getPartAdjustmentTypes);
  const latestJobName = useSelector(getLatestJobName);
  const currentWorkOrderOp = useSelector(getCurrentWorkOrderOp);
  const currentLaborTicket = useSelector(getCurrentLaborTicket);
  const machine = useSelector(getMachine);

  const [initialState, setInitialState] = useState({
    workOrderOp: currentWorkOrderOp,
    activityType: latestActivityType,
    jobName: latestJobName,
    laborTicket: currentLaborTicket,
  });

  useEffect(() => {
    setInitialState({
      workOrderOp: currentWorkOrderOp,
      activityType: latestActivityType,
      jobName: latestJobName,
      laborTicket: currentLaborTicket,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { workOrderOp, activityType, jobName, laborTicket } = initialState;
  const scopeStart = useSelector(getScopeStart);
  const operators = useSelector(getOperators);
  const erpPerson = currentLaborTicket?.personId;
  const operator =
    operators.find((op) => {
      return op.erpId === erpPerson;
    })?.name || 'Not Found';
  const workOrderId = _get(workOrderOp, 'workOrderId');
  const workOrder = _get(workOrderOp, 'workOrder');

  const laborTicketPartsMade = useSelector(getLaborScopeTotalParts);

  const partAdjustment = form.totalParts.value - laborTicketPartsMade;
  const rejectedParts = form.rejectedParts?.value || 0;
  const rejectReason = form.rejectReason?.value || 0;
  const goodParts = form.totalParts.value - rejectedParts;

  const nowISO = toISO(now().valueOf());

  const requestLaborTicketAction = useSubmitLaborTicket({ form });

  const [
    createPartAdjustment,
    { loading: loadingPartAdjustment },
  ] = useMutation(CREATE_PART_ADJUSTMENT, {
    fetchPolicy: 'no-cache',
    onCompleted: ({ createPartAdjustmentResponse }) => {
      dispatch(actionCreatePartAdjustmentSuccess(createPartAdjustmentResponse));
    },
  });

  const orderTotalParts =
    workOrderOp?.totalGoodParts + partAdjustment - rejectedParts;
  const workOrderRedirectPath = useGenerateWorkOrderRedirectPath({
    excludeRefFromPath: true,
  });

  const hasOpenSetupActivity = useSelector(getHasOpenSetupActivity);
  const laborTicketAction = useSelector(getLaborTicketAction);
  const productionActivityTypes = useSelector(getProductionActivityTypeRefs);
  const setupActivityTypes = useSelector(getSetupActivityTypeRefs);

  const requestOperatorLogout = useOperatorLogout();

  const laborTicketMarkedAsComplete = useMemo(() => {
    return form?.workOrderComplete?.value === true;
  }, [form]);

  const laborTicketFullfilled = useMemo(() => {
    return orderTotalParts >= workOrderOp?.finishQuantity;
  }, [orderTotalParts, workOrderOp]);

  const shouldStopSetupAndStartProduction = useMemo(() => {
    return (
      [
        LABOR_TICKET_ACTIONS.STOP_AND_CREATE,
        LABOR_TICKET_ACTIONS.STOP_AND_CREATE_OPERATOR_LOGOUT,
      ].includes(laborTicketAction) && hasOpenSetupActivity === true
    );
  }, [laborTicketAction, hasOpenSetupActivity]);

  const shouldPauseSetupAndLogout = useMemo(() => {
    return (
      enableStopJobAndSignOut &&
      LABOR_TICKET_ACTIONS.STOP_AND_CREATE_OPERATOR_LOGOUT ===
        laborTicketAction &&
      hasOpenSetupActivity === true &&
      !laborTicketMarkedAsComplete
    );
  }, [
    enableStopJobAndSignOut,
    laborTicketAction,
    hasOpenSetupActivity,
    laborTicketMarkedAsComplete,
  ]);

  /** Production */
  const shouldSelectJobAfterStopProduction = useMemo(() => {
    return (
      [
        LABOR_TICKET_ACTIONS.STOP_AND_CREATE,
        LABOR_TICKET_ACTIONS.STOP_AND_CREATE_OPERATOR_LOGOUT,
      ].includes(laborTicketAction) && hasOpenSetupActivity !== true
    );
  }, [laborTicketAction, hasOpenSetupActivity]);

  const stopJobAndSignout = () => {
    if (enableStopJobAndSignOut) {
      return requestLaborTicketAction({
        action: LABOR_TICKET_ACTIONS.OPERATOR_LOGOUT,
        opts: {
          shouldStopCurrentRun: false,
        },
      });
    }

    return null;
  };

  // NOTE: This is a temporary solution to prevent the new setup flow from being used
  const useNewSetupFlow = false;

  // TODO: Implement Pause Hook on extra modal confirmation
  // const handlePauseOperation = useHandlePauseOperation({
  //   onPaused: () => {
  //     if (enableStopJobAndSignOut) {
  //       return requestLaborTicketAction({
  //         action: LABOR_TICKET_ACTIONS.OPERATOR_LOGOUT,
  //         opts: {
  //           shouldStopCurrentRun: false,
  //         },
  //       });
  //     }

  //     return null;
  //   },
  // });

  const [
    SetupLaborTicketStopOrLeaveModal,
    setSetupStopOrLeaveModalOpen,
    setupRequestStopOrLeaveConfirmation,
  ] = useSetupLaborTicketStopOrLeaveModal({
    enableStopJobAndSignOut,
    laborTicketMarkedAsComplete,
    handleStopJob: () => {
      if (
        laborTicketAction ===
        LABOR_TICKET_ACTIONS.STOP_AND_CREATE_OPERATOR_LOGOUT
      ) {
        requestLaborTicketAction({
          action: LABOR_TICKET_ACTIONS.STOP_RUN,
          opts: { operatorLogout: true },
        });

        return requestOperatorLogout();
      }
      return handleSubmit({
        workOrderRedirectPath,
      });
    },
    handleLeaveOpen: () => {
      if (
        laborTicketAction ===
        LABOR_TICKET_ACTIONS.STOP_AND_CREATE_OPERATOR_LOGOUT
      ) {
        requestLaborTicketAction({
          action: LABOR_TICKET_ACTIONS.CHANGE_ACTIVITIES,
          opts: {
            pendingActivityTypeRef: setupActivityTypes[0],
            operatorLogout: true,
          },
        });

        return requestOperatorLogout();
      }
      return requestLaborTicketAction({
        action: LABOR_TICKET_ACTIONS.CHANGE_ACTIVITIES,
        opts: {
          pendingActivityTypeRef: setupActivityTypes[0],
        },
      });
    },
    handleStopJobAndSignOut: () => {
      return stopJobAndSignout();
    },
  });

  const [
    ProductionLaborTicketStopOrLeaveModal,
    setProductionStopOrLeaveModalOpen,
    productionRequestStopOrLeaveConfirmation,
  ] = useProductionLaborTicketStopOrLeaveModal({
    laborTicketMarkedAsComplete,
    enableStopJobAndSignOut,
    handleStopJob: () => {
      if (
        laborTicketAction ===
        LABOR_TICKET_ACTIONS.STOP_AND_CREATE_OPERATOR_LOGOUT
      ) {
        requestLaborTicketAction({
          action: LABOR_TICKET_ACTIONS.STOP_RUN,
          opts: { operatorLogout: true },
        });

        return requestOperatorLogout();
      }

      if (shouldSelectJobAfterStopProduction) {
        return handleSubmit({
          workOrderRedirectPath,
        });
      }
      return handleSubmit();
    },
    handleLeaveOpen: () => {
      if (shouldSelectJobAfterStopProduction) {
        if (
          laborTicketAction ===
          LABOR_TICKET_ACTIONS.STOP_AND_CREATE_OPERATOR_LOGOUT
        ) {
          requestLaborTicketAction({
            action: LABOR_TICKET_ACTIONS.CHANGE_ACTIVITIES,
            opts: {
              pendingActivityTypeRef: productionActivityTypes[0],
              operatorLogout: true,
            },
          });

          return requestOperatorLogout();
        }

        return requestLaborTicketAction({
          action: LABOR_TICKET_ACTIONS.CHANGE_ACTIVITIES,
          opts: {
            pendingActivityTypeRef: productionActivityTypes[0],
          },
        });
      }
      return handleSubmit();
    },
    handleStopJobAndSignOut: () => {
      return stopJobAndSignout();
    },
  });

  const [
    MarkAsCompleteModal,
    setMarkAsCompleteModalOpen,
    requestMarkAsCompleteConfirmation,
  ] = useLaborTicketMarkAsCompleteModal({
    laborTicketMarkedAsComplete,
    laborTicketFullfilled,
    handleMarkAsComplete: () => {
      if (shouldSelectJobAfterStopProduction) {
        return handleSubmit({
          workOrderRedirectPath,
        });
      }

      return handleSubmit();
    },
  });

  const handleSubmit = async (opts) => {
    if (partAdjustment) {
      await createPartAdjustment({
        variables: {
          input: {
            count: partAdjustment,
            machine_id: machine.id,
          },
        },
      });
    }
    if (rejectedParts) {
      const partAdjustmentTypeId = rejectReason.rejectReasonRef;
      const partAdjustmentType = partAdjustmentTypes.find((type) => {
        return type.id === rejectReason.rejectReasonRef;
      });

      const rejectBehavior =
        partAdjustmentType.rejectBehavior === 'operator'
          ? 'scrap'
          : partAdjustmentType.rejectBehavior;

      await createPartAdjustment({
        variables: {
          input: {
            scrapcount: rejectedParts,
            machine_id: machine.id,
            count: 0,
            reject_behavior: rejectBehavior,
            category_id: partAdjustmentTypeId,
            message: null,
          },
        },
      });
    }

    return requestLaborTicketAction({
      action: mapLaborTicketAction(laborTicketAction),
      opts,
    });
  };

  const {
    isSetupPauseActionsModalOpen,
    setIsSetupPauseModalOpen,
    canOpenSetupPauseActionModal,
    isSetupConfirmStopJobModalOpen,
    ...setupStopJobOrPauseProps
  } = useSetupPauseActions({
    laborTicketMarkedAsComplete,
    handleSubmit,
    requestLaborTicketAction,
  });

  const { handlePause: pauseOperationAndLogout } = useSetupPauseActions({
    laborTicketMarkedAsComplete,
    handleSubmit,
    requestLaborTicketAction,
  });

  const {
    shouldShowIncompleteSetupModal,
    setShowIncompleteSetupModal,
    isLogout,
    showConfirmationModal,
    handleCancelConfirmationModal,
    ...setupPauseActionProps
  } = useSetupPauseActionsWithStopJobAndSignOutFlag({
    laborTicketMarkedAsComplete,
    requestLaborTicketAction,
    handleSubmit,
  });

  const setupIncompleteModalProps = {
    ...setupPauseActionProps,
    setShowIncompleteSetupModal,
    isLogout,
  };

  return (
    <>
      <SetupIncompleteModal {...setupIncompleteModalProps} />
      <SetupConfirmStopJobModal
        isOpen={showConfirmationModal}
        handleCancel={handleCancelConfirmationModal}
        isLogout={isLogout}
        {...setupIncompleteModalProps}
      />
      {SetupLaborTicketStopOrLeaveModal}
      <SetupStopJobOrPauseModal
        {...setupStopJobOrPauseProps}
        isOpen={isSetupPauseActionsModalOpen}
      />
      <SetupConfirmStopJobModal
        {...setupStopJobOrPauseProps}
        isOpen={isSetupConfirmStopJobModalOpen}
      />
      {MarkAsCompleteModal}
      {ProductionLaborTicketStopOrLeaveModal}

      <Card
        header={
          <>
            <Icon icon={farTicketAlt} style={{ marginRight: '0.5rem' }} />
            Labor Ticket
          </>
        }
        body={
          <BodyContainer>
            <ColumnContainer>
              <LaborTicketItem label="Operator" value={operator} />
              <LaborTicketItem label="Production order" value={workOrderId} />
              <Row>
                <LaborTicketItem label="Lot" value={workOrder?.lot} />
                <LaborTicketItem label="Split" value={workOrder?.split} />
                <LaborTicketItem label="Sub" value={workOrder?.sub} />
              </Row>
              <LaborTicketItem label="Part Operation" value={jobName} />
              <LaborTicketItem
                label="Order Progress"
                value={`${orderTotalParts?.toLocaleString() || '-'} / ${
                  workOrderOp?.finishQuantity
                }`}
              />
            </ColumnContainer>

            <ColumnContainer>
              <LaborTicketItem label="Total Good Parts" value={goodParts} />
              <LaborTicketItem
                label="Total Rejects"
                value={form.rejectedParts.value}
              />
              <LaborTicketItem
                label="Started"
                value={
                  laborTicket?.clockIn
                    ? `${moment(laborTicket.clockIn).format('L')} ${moment(
                        laborTicket.clockIn
                      ).format('LT')}`
                    : `${moment(scopeStart).format('L')} ${moment(
                        scopeStart
                      ).format('LT')}`
                }
              />
              <LaborTicketItem
                label="Ended"
                value={`Today ${moment(nowISO).format('LT')}`}
              />
              <LaborTicketItem label="Activity" value={activityType.name} />
            </ColumnContainer>
          </BodyContainer>
        }
        footer={
          <ModalButton
            customStyles={{
              background: palette.Indigo600,
              display: 'flex',
              alignItems: 'center',
              gap: '.5rem',
            }}
            hoverStyles={{
              background: palette.Indigo700,
            }}
            disabled={
              loading ||
              (!rejectReason && rejectedParts) ||
              loadingPartAdjustment
            }
            // eslint-disable-next-line consistent-return
            onClick={() => {
              if (!loading) {
                if (form.workOrderComplete.value === null) {
                  return handleFormChange(
                    'workOrderComplete',
                    null,
                    `You must select a status for the ${
                      hasOpenSetupActivity ? 'setup' : 'production'
                    }`
                  );
                }

                if (shouldPauseSetupAndLogout && useNewSetupFlow) {
                  return pauseOperationAndLogout();
                }

                if (shouldShowIncompleteSetupModal && useNewSetupFlow) {
                  return setShowIncompleteSetupModal(true);
                }

                if (canOpenSetupPauseActionModal && useNewSetupFlow) {
                  return setIsSetupPauseModalOpen(true);
                }

                if (setupRequestStopOrLeaveConfirmation) {
                  return setSetupStopOrLeaveModalOpen(true);
                }

                if (productionRequestStopOrLeaveConfirmation) {
                  return setProductionStopOrLeaveModalOpen(true);
                }

                if (requestMarkAsCompleteConfirmation) {
                  return setMarkAsCompleteModalOpen(true);
                }

                if (shouldSelectJobAfterStopProduction) {
                  return handleSubmit({
                    workOrderRedirectPath,
                  });
                }

                // setup complete
                if (
                  laborTicketMarkedAsComplete &&
                  shouldStopSetupAndStartProduction
                ) {
                  if (
                    laborTicketAction ===
                    LABOR_TICKET_ACTIONS.STOP_AND_CREATE_OPERATOR_LOGOUT
                  ) {
                    requestLaborTicketAction({
                      action: LABOR_TICKET_ACTIONS.CHANGE_ACTIVITIES,
                      opts: {
                        pendingActivityTypeRef: productionActivityTypes[0],
                        operatorLogout: true,
                      },
                    });

                    return requestOperatorLogout();
                  }

                  return requestLaborTicketAction({
                    action: LABOR_TICKET_ACTIONS.CHANGE_ACTIVITIES,
                    opts: {
                      pendingActivityTypeRef: productionActivityTypes[0],
                    },
                  });
                }

                return handleSubmit();
              }
            }}
          >
            {loading && <LoadingCircle icon={spinner} size="1.25rem" />} Submit
            to ERP
          </ModalButton>
        }
      />
    </>
  );
};

LaborTicketCard.propTypes = {
  form: PropTypes.object,
  loading: PropTypes.bool,
  handleFormChange: PropTypes.func,
};

export default LaborTicketCard;
